import $ from "jquery";
const $notificationBar = $(".notification-bar");
const $notificationPopup = $(".notification-popup");
const $closeNotification = $(".close-notification");

if ($notificationBar.length) {
    $closeNotification.click(function() {
        // Close Notification
        $notificationBar.slideToggle();
        // Set Cookie
        var cookie = $(".notification-bar").attr("data-cookie"),
            unique = $(".notification-bar").attr("data-unique");
        // Check if set
        if (typeof cookie !== typeof undefined && cookie !== false) {
            // Set Cookie for 1 year
            Cookies.set(unique, cookie, {
                expires: 365,
            });
        }
    });

    // If Cookie is enabled but close is not
    if ($closeNotification.length == 0) {
        // Set Cookie
        var cookie = $notificationBar.attr("data-cookie"),
            unique = $notificationBar.attr("data-unique");

        // Check if set
        if (typeof cookie !== typeof undefined && cookie !== false) {
            // Set Cookie for 1 year
            Cookies.set(unique, "cookie-" + cookie, {
                expires: 365,
            });
        }
    }
}

if ($notificationPopup.length) {
    // Fix toggle - Reinitalize Foundation for this element. Not sure why...
    $notificationPopup.foundation();

    $notificationPopup.foundation("toggle");

    // Set Cookie
    var cookie = $notificationPopup.attr("data-cookie"),
        unique = $notificationPopup.attr("data-unique");

    // Check if set
    if (typeof cookie !== typeof undefined && cookie !== false) {
        // Set Cookie for 1 year
        Cookies.set(unique, "cookie-" + cookie, {
            expires: 365,
        });
    }
}
