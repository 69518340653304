import jQuery from "jquery";
import throttle from "lodash/throttle";

(function(win, doc, $) {
    // Variables
    var headerHeight = "",
        $win = $(win);

    // Constants
    const smallBreakPoint = 700,
        $content = $("#content"),
        $siteHeader = $(".site-header");

    function resizeWindowHander() {
        let header_is_fixed = doc
            .querySelector(".site-header")
            .classList.contains("sticky");

        if (header_is_fixed && $win.width() < smallBreakPoint) {
            let currentHeaderHeight = $siteHeader.height();

            if (headerHeight != currentHeaderHeight) {
                headerHeight = currentHeaderHeight;

                // Push down #content based on the headers height
                $content.css("padding-top", currentHeaderHeight);
            }

            // Push down off canvas menu and overlay if .off-canvas data-transition="fulloverlay"
            // setTimeout(function() {
            //     $( ".off-canvas.is-transition-fulloverlap, .off-canvas.is-transition-fulloverlap ~ .js-off-canvas-overlay" )
            //         .css("top", headerHeight)
            //         .addClass( 'js-header-height' );
            // }, 1000);

            return true;
        } else if (Foundation.MediaQuery.atLeast("medium")) {
            $content.removeAttr("style");

            return false;
        }
    }

    // Init on page load
    resizeWindowHander();

    // Init on window resize
    $win.on("resize", throttle(resizeWindowHander, 50, { leading: false }));
})(window, document, jQuery);
