import $ from "jquery";
const $headerFixed = $(".site-header:not(.site-header--scroll-reveal)");
const $socialBar = $(".social-bar");

/**
 * Methods
 */
function fixedHeaderResize() {
    let scroll = getCurrentScroll();
    let isTransparent = $headerFixed.hasClass('transparent');

    if (scroll >= $headerFixed.innerHeight() / 2) {
        $headerFixed.addClass("small");
        $socialBar.slideUp();

        // Header is set to be transparent
        if ( isTransparent ) {
            $headerFixed.addClass("is-opaque");
        }
    } else {
        $headerFixed.removeClass("small");
        $socialBar.slideDown();

        if ( isTransparent ) {
            $headerFixed.removeClass("is-opaque");
        }
    }
}

/**
 * Events/init
 */
if ($headerFixed.length) {
    window.addEventListener("scroll", fixedHeaderResize, false);
}

function getCurrentScroll() {
    return window.pageYOffset || document.documentElement.scrollTop;
}
