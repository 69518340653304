import $ from "jquery";
import "slick-carousel";

// Ensure slick library has been included
if ( typeof $.fn.slick == "function" ) {
    
    const $js_slider = $( ".js-slider" );
    
    const slick_config = {
        prevArrow:
            '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><i class="fal fa-angle-left fa-3x" aria-hidden="true"></i></button></i>',
        nextArrow:
            '<button class="slick-next slick-arrow" aria-label="Next" type="button"><i class="fal fa-angle-right fa-3x" aria-hidden="true"></i></button>',
    };

    if ( $js_slider.parents( '.nv-carousel-block' ) ) {

        slick_config.appendDots = $js_slider.parents( '.nv-carousel-block' ).find('.slider-component__cell');

    }

    $js_slider.slick(slick_config);
    
    // Capture the gallery image index
    var gallery_slide_index = false;
    
    $('.nv-gallery-block .gallery > .gallery-item').on( 'click', function() {
    
        gallery_slide_index = $(this).index();
    
    } );

    // Capture the carousel image index
    var carousel_slide_index = false;
    
    $('.slider-component__slide > a').on( 'click', function() {
    
        carousel_slide_index = $(this).parents('.slick-slide').attr('data-slick-index');
        
    } );

    // Gallery and Carousel blocks.
    // Open event on reveal modals.
    // $(this) is scoped to the opened reveal modal.

    $(document).on( 'open.zf.reveal', '[data-reveal]', function (a,b,c) {
        
        var $reveal = $(this);

        /* Gallery Block */   
       
        if ( $reveal.find( '.js-gallery-slider' )  ) {

            const $gallery_slider = $reveal.find( '.js-gallery-slider' );

            setTimeout( function() {
                
                // Detect if the slick slideer has already been initiated on .js-gallery-slider 
                // by detecting the presence of the .slick-slider class.  
                if ( $gallery_slider && ! $gallery_slider.hasClass( 'slick-slider' ) ) {
                    
                    // Init Slick if it is not already intialized.
                    $gallery_slider.slick({
                        centerMode: true,
                        infinite: false,
                        fade: true,
                        appendArrows: $reveal,
                        prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><i class="fal fa-angle-left fa-3x" aria-hidden="true"></i></button></i>',
                        nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"><i class="fal fa-angle-right fa-3x" aria-hidden="true"></i></button>'
                    });

                    $(window).trigger('resize');

                    if ( gallery_slide_index != false ) {

                        $gallery_slider.slick('slickGoTo', gallery_slide_index);
                        
                        gallery_slide_index = false;
                    }

                }
            
            }, 750);

        }

        /* Carousel Block */

        if ( $reveal.find( '.js-carousel-slider' )  ) {

            const $carousel = $reveal.find( '.js-carousel-slider' );

            setTimeout( function() {
                
                // Detect if the slick slideer has already been initiated on .js-gallery-slider 
                // by detecting the presence of the .slick-slider class.  
                if ( $carousel && ! $carousel.hasClass( 'slick-slider' ) ) {
                    
                    // Init Slick if it is not already intialized.
                    $carousel.slick({
                        centerMode: true,
                        infinite: false,
                        fade: true,
                        appendArrows: $reveal,
                        prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><i class="fal fa-angle-left fa-3x" aria-hidden="true"></i></button></i>',
                        nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"><i class="fal fa-angle-right fa-3x" aria-hidden="true"></i></button>'
                    });

                    $(window).trigger('resize');

                    if ( carousel_slide_index != false ) {

                        $carousel.slick('slickGoTo', carousel_slide_index);
                        
                        carousel_slide_index = false;
                    }

                }

            }, 750);

        }

    } );
    
    // Gallery and Carousel blocks.
    // Closed event on reveal modals.
    // $(this) is scoped to the opened reveal modal.
    $(document).on( 'closed.zf.reveal', '[data-reveal]', function () {
        
        if ( $(this).find( '.js-gallery-slider' ) ) {

            $(this).find( '.js-gallery-slider' ).slick('unslick');
        
        }

        if ( $(this).find( '.js-carousel-slider' ) ) {

            $(this).find( '.js-carousel-slider' ).slick('unslick');

        }

    } );

}

$('.location-slider').slick({
    dots: false,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><i class="fas fa-chevron-left"></i></button></i>', 
    nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"><i class="fas fa-chevron-right"></i></button>', 
    
    responsive: [
        {
            breakpoint: 900 ,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
                }
        },
        {
            breakpoint: 640,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
                }
        }
    ]
}); 