import $ from "jquery";

const slider_bars = `
    <i class="slider__bar slider__bar--vertical" aria-hidden="true"></i>
    <i class="slider__bar slider__bar--horizontal" aria-hidden="true"></i>
`;

const slider_arrow = `
    <i aria-hidden="true" class="fal fa-angle-down fa-lg"></i>
`;

$(".off-canvas__menu .menu-item-has-children").append(
    `<button aria-label="Expand menu" class="slider js-submenu-slider">
        ${slider_arrow}
    </<button>`
);

// Expand/collapse on-click
$(".js-submenu-slider").click(function(e) {
    e.preventDefault();
    $(this)
        .toggleClass("active")
        .siblings(".sub-menu")
        .finish()
        .slideToggle();
});

// Add class to the body tag to prevent page scrolling when the off-canvas menu is open
let $offcanvas_toggle = $( '.off-canvas__toggle, .off-canvas > .close-button' );

$offcanvas_toggle.on( 'click', function( event ) {
    document.body.classList.toggle( 'js-prevent-vertical-scrolling' );
} );
