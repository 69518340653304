import $ from "jquery";
// import "@babel/polyfill";
import Foundation from "./_foundation";
// import "motion-ui/dist/motion-ui";
import throttle from "lodash/throttle";
 import AOS from 'aos'
// import '../../node_modules/jquery-parallax.js/parallax.min.js'
import '../../node_modules/focus-visible/dist/focus-visible.min.js';
import { inlineSVG } from "./_inline-svg";
import {
    lazyImages,
    lazyBackgrounds,
    lazyVideos,
    lazyOembeds,
    lazyInterchange,
} from "./_lazy-load";
// import { smoothScrollOnPageLoad } from "./_smoothScrollOnPageLoad";
import "./_skip-link-focus-fix";
import "./_acf-map";
import "./_fixed-header";
import "./_header-height";
// import './_hero-slider'
import "./_notification";
import "./_scroll-to-top";
import './_slick';
import './_social-share-sticky';
import './_off-canvas'
// import './_faqs_accordion'
// import './_fragmentURLs'

/* Additional Scripts */
// import { randomString } from './_random-string'
// import { equalHeights } from './_equal-height'
import "./_ie";

import "./_site-header-search";

// Foundation
$(document).foundation();

// Inline SVG
inlineSVG();

// Smooth Scroll on page load
// smoothScrollOnPageLoad();

// Lazy-load on DOMContentLoaded
window.addEventListener(
    "DOMContentLoaded",
    function() {
        lazyImages();
        lazyBackgrounds();
        lazyVideos();
        lazyOembeds();
        lazyInterchange();
    },
    false,
);

// Lazy-load on scroll event
window.addEventListener("scroll", throttle(lazyImages, 75), {
    passive: true,
});
window.addEventListener("scroll", throttle(lazyBackgrounds, 75), {
    passive: true,
});
window.addEventListener("scroll", throttle(lazyOembeds, 75), {
    passive: true,
});
window.addEventListener("scroll", throttle(lazyInterchange, 75), {
    passive: true,
});

// AOS
 AOS.init();


/**
 * Scroll reveal header
 */
var didScroll;
var lastScrollTop = 0;
var delta = 5;
var $siteHeight = $('.site-header.site-header--scroll-reveal');
var navbarHeight = $siteHeight.outerHeight();
// var screenSize = Foundation.MediaQuery.current;
    
// Seup of detection the scroll direction
function detectScrollDirection() {
    
    $( window ).scroll( function( event ) {
        didScroll = true;
    } );

    setInterval( function() {
        if ( didScroll ) {
            hasScrolled();
            didScroll = false;
        }
    }, 150);

    function hasScrolled() {

        var scrollTop = $(window).scrollTop();

        // Make sure they scroll more than delta.
        if ( Math.abs( lastScrollTop - scrollTop ) <= delta ) {
            return;
        }
        
        // Scrolled down.
        if ( scrollTop > lastScrollTop ) {

            $siteHeight.removeClass('js-nav-down').addClass('js-nav-up');

        } 

        // Scrolled up.
        else {

            // Scroll Up
            if ( scrollTop < ( navbarHeight + delta ) ) {
            
                $siteHeight.removeClass('js-nav-down').addClass('js-nav-up');
            
            }
            
            else if (scrollTop + $(window).height() < $(document).height()) {
            
                $siteHeight.removeClass('js-nav-up').addClass('js-nav-down');
            
            }

        }
        
        lastScrollTop = scrollTop;
    }

}

// Init detection on load
detectScrollDirection();



// NV Scroll Spy
// 
// NOTE: Add CSS to control visibility, position etc. 
// 
// Example: 
//     [data-fadeup] { opacity: 0; transition: 2s all; }
//     [data-fadeup="true"] { opacity: 1; }

const nvScrollSpy = ( dataAttr = 'data-fadeup', settingsObj = { rootMargin: "0px 0px -150px 0px" } ) => {

    // Create an array from the returned nodelist.
    let fadeIns = Array.prototype.slice.call( document.querySelectorAll('[' + dataAttr + ']') );

    // Exit if no DOM elements are found.
    if ( fadeIns.length == 0 ) {

        console.warn( 'nvScrollSpy() needs a valid selector. No matching elements have been found.' );

        return false;

    }

    // Set up the IntersectionObserver.
    let fadeInObserver = new IntersectionObserver( ( entries, observer ) => { 

        entries.forEach( entry => {

            // When el is fully visible.
            if ( entry.intersectionRatio != 0 ) {

                // entry.target.dataset.fadein = 'true';
                entry.target.setAttribute(dataAttr, 'true');

            } 
          
        } );

    }, settingsObj );

    // Add the IntersectionObserver to each DOM element using the observe method.
    fadeIns.forEach( ( fadein ) => {

      fadeInObserver.observe( fadein );

    } );

}


nvScrollSpy( 'data-fadeup', { rootMargin: "0px 0px -50px 0px" } );

// Woocommerce mobile menu 
$('.woocommerce-mobile-nav__toggle').click(function(){
    $(this).toggleClass('active'); 
    $('.woocommerce-MyAccount-navigation ul').toggleClass('is-open'); 
});

// Dashboard login buttons
$('#js-login').click(function(){
    $('#js-register').removeClass('active'); 
    $(this).addClass('active'); 
    $('#customer_login .col-1').show(); 
    $('#customer_login .col-2').hide(); 
});

$('#js-register').click(function(){
    $('#js-login').removeClass('active'); 
    $(this).addClass('active'); 
    $('#customer_login .col-1').hide(); 
    $('#customer_login .col-2').show(); 
});
 
$('#js-archive-toggle button').click(function(e) {
  var clickedButtonId = $(this).attr('id');
  $(this).addClass('active');

  if (clickedButtonId === 'card-view') {
    $('#list-view').removeClass('active'); 
    $('.archive-grid').removeClass('list').addClass('card');
    console.log('card');
  } else if (clickedButtonId === 'list-view') {
    $('#card-view').removeClass('active');
    $('.archive-grid').removeClass('card').addClass('list');
    console.log('list');
  }
});

