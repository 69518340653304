import throttle from "lodash/throttle";
import jQuery from "jquery";

jQuery( function( $ ) {

    var $share_post = $('.single.post-template-nv-blog-post .share-post'),
        $post_author = $('.single.post-template-nv-blog-post .nv-author')

    $(window).scroll( throttle( function() {
        
        var $window = $(this),
            bottom_bounds = '';

        if ( $share_post.length ) {

            var bottom_bounds = $post_author.length ? $post_author.offset().top : 0;
            
            if ( $window.scrollTop() >= 677 ) {

                if ( $share_post.hasClass('is-sticky') == false ) {
                
                    $share_post.addClass('is-sticky');

                }

            } else {
                
                $share_post.removeClass('is-sticky');

            }

            if ( window.pageYOffset >= ( bottom_bounds - 325 ) ) {

                $share_post.fadeOut(200);

            } else {

                $share_post.fadeIn(200);
                
            }
        }

    }, 75 ) );

} );