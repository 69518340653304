// jshint esversion: 6
(function($) {
    // vars
    let apiLoaded =
        "object" === typeof google && "object" === typeof google.maps;
    let acfMaps = document.querySelectorAll(".acf-map");
    let googleMaps = [];
    let customMapStyles = [
        {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [
                {
                    color: "#444444",
                },
            ],
        },
        {
            featureType: "landscape",
            elementType: "all",
            stylers: [
                {
                    color: "#f2f2f2",
                },
            ],
        },
        {
            featureType: "poi",
            elementType: "all",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "poi",
            elementType: "labels.text",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "road",
            elementType: "all",
            stylers: [
                {
                    saturation: -100,
                },
                {
                    lightness: 45,
                },
            ],
        },
        {
            featureType: "road.highway",
            elementType: "all",
            stylers: [
                {
                    visibility: "simplified",
                },
            ],
        },
        {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "transit",
            elementType: "all",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "water",
            elementType: "all",
            stylers: [
                {
                    color: "#43516f",
                },
                {
                    visibility: "on",
                },
            ],
        },
    ];
    let customMapMarker = {
        path:
            "M27.648-41.399q0-3.816-2.7-6.516t-6.516-2.7-6.516 2.7-2.7 6.516 2.7 6.516 6.516 2.7 6.516-2.7 2.7-6.516zm9.216 0q0 3.924-1.188 6.444l-13.104 27.864q-.576 1.188-1.71 1.872t-2.43.684-2.43-.684-1.674-1.872l-13.14-27.864q-1.188-2.52-1.188-6.444 0-7.632 5.4-13.032t13.032-5.4 13.032 5.4 5.4 13.032z",
        fillColor: "#43516f", // MARKER COLOR
    };

    // Create info window outside of each - then tell that singular infowindow to swap content based on click
    if (apiLoaded) {
        let infowindow = new google.maps.InfoWindow({
            content: "",
        });
    }

    // Renders a Google Map onto the selected jQuery element
    function new_map($el) {
        // all map markers
        let $markers = $el.find(".marker");

        // map args
        let args = {
            zoom: 16,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
        };

        // Custom map styles used if .acf-map has .custom-styles class
        let useCustomStyles = $el.hasClass("custom-styles");

        // create map
        let map = new google.maps.Map($el[0], args);

        // add a markers reference
        map.markers = [];

        // map options
        map.setOptions({
            styles: useCustomStyles ? customMapStyles : null,
            scrollwheel: false,
        });

        // add markers to map
        for (let i = 0; i < $markers.length; i++) {
            add_marker($($markers[i]), map, useCustomStyles);
        }

        // center map on page load
        center_map(map);

        // return
        return map;
    }

    // Adds a marker to the selected Google Map
    function add_marker($marker, map, useCustomMarker = false) {
        // marker lat/lng
        let lat = $marker.attr("data-lat");
        let lng = $marker.attr("data-lng");
        let latlng = new google.maps.LatLng(lat, lng);

        // marker args
        let args = {
            position: latlng,
            map: map,
        };

        // apply custom marker if .acf-map has .custom-styles
        if (useCustomMarker) {
            args.icon = {
                path: customMapMarker.path,
                scale: 0.65,
                fillColor: customMapMarker.fillColor,
                fillOpacity: 1,
            };
        }

        // create marker
        let marker = new google.maps.Marker(args);

        // add to array
        map.markers.push(marker);

        // if marker contains HTML, add it to an infoWindow
        if ($marker.html()) {
            // show info window when marker is clicked & close other markers
            google.maps.event.addListener(marker, "click", function() {
                //swap content of that singular infowindow
                infowindow.setContent($marker.html());
                infowindow.open(map, marker);
            });

            // close info window when map is clicked
            google.maps.event.addListener(map, "click", function(event) {
                if (infowindow) {
                    infowindow.close();
                }
            });
        }
    }

    // Centers the map, showing all markers attached to this map
    function center_map(map) {
        let bounds = new google.maps.LatLngBounds();
        let mapMarkers = map.markers;

        // loop through all markers and create bounds
        for (let i = 0; i < mapMarkers.length; i++) {
            let mapMarker = mapMarkers[i];
            let lat = mapMarker.position.lat();
            let lng = mapMarker.position.lng();
            let latlng = new google.maps.LatLng(lat, lng);

            bounds.extend(latlng);
        }

        // determine map position based on marker count
        if (mapMarkers.length == 1) {
            // set center of map
            map.setCenter(bounds.getCenter());
            map.setZoom(16);
        } else {
            // fit to bounds
            map.fitBounds(bounds);
        }
    }

    // Wait until page loaded to create maps/center markers
    $(function() {
        if (apiLoaded) {
            // Render all maps
            if (acfMaps.length) {
                for (let i = 0; i < acfMaps.length; i++) {
                    let map = new_map($(acfMaps[i]));
                    googleMaps.push(map);
                }
            }

            // Center maps on resize
            if (googleMaps.length) {
                google.maps.event.addDomListener(window, "resize", function() {
                    for (let i = 0; i < googleMaps.length; i++) {
                        center_map(googleMaps[i]);
                    }
                });
            }
        }
    });
})(jQuery);
