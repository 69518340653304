import $ from "jquery";

export function inlineSVG(svgImgSelector = ".svg") {
    // Push SVG to inline so we can edit them
    $("img" + svgImgSelector).each(function() {
        let $this = $(this);
        $.get(
            $this.attr("src"),
            function(data) {
                let $svg = $(data)
                    .find("svg")
                    .removeAttr("xmlns:a");
                $this.replaceWith($svg);
            },
            "xml",
        );
    });
}
