import $ from "jquery";
var $scrollToTop = $(".scroll-to-top"),
    $fadeInScrollToTop = $(".scroll-to-top--right");

// Show/Hide elements page on page scroll
$(window).on("scroll", function(e) {
    // If the user has scrolled the page > 500px...
    if ($(this).scrollTop() > 500) {
        $fadeInScrollToTop.fadeIn(200);

        // Hide somewhere near the bottom of the page
        if (
            $(this).scrollTop() == -($(window).height() - $(document).height())
        ) {
            // Do Something
        }

        // If the user scroll up past the 500px threshold then fade out.
    } else {
        $fadeInScrollToTop.fadeOut(200);
    }
});

// Add Click event listener for when to scroll to the top. Done.
$scrollToTop.on("click", function() {
    $("html, body").animate(
        {
            scrollTop: 0,
        },
        "fast",
    );
    return false;
});
