import jQuery from "jquery";

jQuery( function( $ ) {

    // Site Search
    $( '.menu-item--search > a' ).on( 'click', function( event ) {
        
        event.preventDefault();

        var $search_bar = $( '.site-search' );

        $( '.site-navigation' ).fadeToggle( 250, function() {
            
            setTimeout( function() {
                $( '.site-search' ).fadeIn( 150 );
                $('.search-form__input').focus();    
            }, 150 );

        } );

    } );

    // Site Search (Mobile)
    let $mobile_header_elements = $( '.site-branding__logo, [data-toggle="offCanvas"]' ),
        $search_bar = $( '.site-search' ),
        $mobile_search = $( '.off-canvas__toggle--search' )

    $mobile_search.on( 'click', function( event ) {

        event.preventDefault();

        // Add Class to close button for mobile
        $search_bar.find('.site-search__close').addClass('site-search__close--mobile');

        $mobile_header_elements.add(this).fadeToggle( 250, function() {

            // $search_bar.fadeIn( 150 );

            // $('.search-form__input').focus();

        } );

    } );

    // Site Search mobile and desktop close button

    $( '.site-search__close' ).on( 'click', function(event) {

        event.preventDefault();

        // Mobile close button

        if ( $('.site-search__close').hasClass('site-search__close--mobile') ) {
            
            $search_bar.fadeOut( 250, function() {

                $mobile_header_elements.fadeIn( 250 );

                $mobile_search.fadeIn( 250 );

            } );

        } 

        // Desktop close button

        else {
            
            $search_bar.fadeToggle( 250, function() {

                $( '.site-navigation' ).fadeToggle( 150 );

                $('.search-form__input').blur();

            } );

        }

    } );

} );