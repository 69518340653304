/**
 * Detect Internet Explorer 11 or Edge
 */
(function(w, d, $) {
    
    /**
     * Detect IE 11
     */
    function isIE11() {
        var isIE11 =
            "-ms-scroll-limit" in document.documentElement.style &&
            "-ms-ime-align" in document.documentElement.style;
        if (isIE11) {
            d.body.classList.add("is-ie");
        } else {
            d.body.classList.add("not-ie");
        }
    }
    isIE11();

    /**
     * Detect Edge
     */
    function isIEorEDGE() {
        if (navigator.appName == "Microsoft Internet Explorer") {
            return true; // IE
        } else if (navigator.appName == "Netscape") {
            return navigator.appVersion.indexOf("Edge") > -1; // EDGE
        }
        return false;
    }

    var edge = isIEorEDGE();
    
    function isEdge(isIEorEDGE) {
        if (isIEorEDGE) {
            document.body.classList.add("is-edge");
            if (document.body.classList.contains("is-ie11")) {
                document.body.classList.remove("is-ie11");
            }
        } else {
            document.body.classList.add("not-edge");
        }
    }

    isEdge(edge);

    /**
     * Detect Chromium Edge
     */
    function isEdgeChromium() {
        let detectChromiumEdge = () => {
          if (navigator.appName == "Netscape") {
            // NOTE: Detecting 'Edg/' might change
            if ( navigator.appVersion.indexOf('Edg/') > -1 || navigator.vendor.indexOf('Google') ) {
              return true; 
            }                       
          } 
        };
        
        let isEdgeChromium = detectChromiumEdge();
        
        if ( isEdgeChromium ) {
            if ( document.body.classList.contains('is-ie') ) {
              // NOTE: Chrome Based Edge script will fasely detect IE as Edge (chrome) 
              document.body.classList.remove('is-edge-chromium');
            } else {
              document.body.classList.add('is-edge-chromium');
            } 
        } else {
          document.body.classList.add('not-edge-chromium');
        }
    }
    isEdgeChromium();
    
})(window, document, jQuery);
