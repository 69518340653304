/**
 * Lazy Load - Foundation 6 Interchange, Images, Background Images, Video, WordPress Oembeds
 */

import jQuery from "jquery";

// lazyInterchange
import { Interchange } from "foundation-sites/js/foundation.interchange";

const $ = jQuery;

export function lazyInterchange() {
    let lazyAssets = document.querySelectorAll("[data-lazy-interchange]"),
        assetsLoaded = 0;

    // Do not perform loop if all assets have loaded

    if (lazyAssets.length < assetsLoaded) return false;

    for (let i = 0; i < lazyAssets.length; i++) {
        let asset = lazyAssets[i];

        // Only request if asset not already loaded

        if (!asset.hasAttribute("data-interchange")) {
            let assetTop = asset.getBoundingClientRect().top - 500;

            let lazyValue = asset.getAttribute("data-lazy-interchange");

            // Request once the bottom of the screen is approaching the asset

            if (window.innerHeight > assetTop) {
                let interchange = new Foundation.Interchange($(asset), {
                    rules: lazyValue,
                });

                assetsLoaded++;
            }
        }
    }

    return true;

    /* Init

        window.addEventListener('load', lazyInterchange, false);
        window.addEventListener('scroll', _throttle( lazyInterchange, 75 ), { passive: true });

     */
}

export function lazyImages() {
    let lazyAssets = document.querySelectorAll("[data-lazy-src]"),
        assetsLoaded = 0;

    // Do not perform loop if all assets have loaded

    if (assetsLoaded < lazyAssets.length) {
        for (let i = 0; i < lazyAssets.length; i++) {
            let asset = lazyAssets[i];

            // Only request if asset has not already been loaded

            if (!asset.hasAttribute("src")) {
                let assetTop = asset.getBoundingClientRect().top - 500;

                let lazyValue = asset.getAttribute("data-lazy-src");

                // Request once the bottom of the screen is approaching the asset

                if (window.innerHeight > assetTop) {
                    asset.setAttribute("src", lazyValue);

                    asset.removeAttribute("data-lazy-src");

                    triggerResize();

                    assetsLoaded++;
                }
            }
        }

        return true;
    }

    /*  Init
        window.addEventListener('load', lazyImages, false);
        window.addEventListener('scroll', _throttle( lazyImages, 75 ), { passive: true });
    */
}

export function lazyBackgrounds() {
    let lazyAssets = document.querySelectorAll("[data-lazy-background]"),
        assetsLoaded = 0;

    // Do not perform loop if all assets have loaded

    if (assetsLoaded < lazyAssets.length) {
        for (let i = 0; i < lazyAssets.length; i++) {
            let asset = lazyAssets[i];

            // Only request if asset not already loaded

            if (!asset.hasAttribute("data-lazy-loaded")) {
                let assetTop = asset.getBoundingClientRect().top - 500;

                let lazyValue = asset.getAttribute("data-lazy-background");

                // Request once the bottom of the screen is approaching the asset

                if (window.innerHeight > assetTop) {
                    asset.style["background-image"] =
                        "url( " + lazyValue + " )";

                    asset.setAttribute("data-lazy-loaded", "true");

                    asset.removeAttribute("data-lazy-background");

                    triggerResize();

                    assetsLoaded++;
                }
            }
        }
    }

    return true;

    /* Init
    window.addEventListener('load', lazyBackgrounds, false);
    window.addEventListener('scroll', _throttle( lazyBackgrounds, 75 ), { passive: true });
    */
}

export function lazyVideos() {
    let videos = document.querySelectorAll(".js-defer-video"),
        $ = jQuery;

    // Quit if node length is 0

    if (videos.length) {
        // Loop through deferred videos and request content

        for (let i = 0; i < videos.length; i++) {
            let videoSources = videos[i].querySelectorAll("source");

            let autoplay = videos[i].hasAttribute("data-deferred-autoplay");

            // Loop through sources to set the [src] (webm and mp4)

            for (let j = 0; j < videoSources.length; j++) {
                let src = videoSources[j].getAttribute("data-src") || "";

                videoSources[j].setAttribute("src", src);
            }

            // Load video content now that we have the source
            videos[i].load();

            // Autoplay the video once loaded - if native [autoplay] is used the
            // video will preload automatically defeating the purpose of the defer
            // since the preload attribute is ignored if autoplay is present.
            if (autoplay) {
                $(videos[i]).one("canplay", function() {
                    this.play();
                    this.style.opacity = 1;
                });
            } else {
                $(videos[i]).one("canplay", function() {
                    this.style.opacity = 1;
                });
            }
        }

        return true;
    }
}

export function lazyOembeds() {
    let oembedVideos = document.getElementsByClassName("js-defer-oembed");

    // Check for deferred element(s) existence

    if (oembedVideos.length) {
        // Set the iframe's [data-src] to the [src]

        function loadContent(iframe) {
            let src = iframe.getAttribute("data-src");

            if (src) {
                iframe.setAttribute("src", src);
            }
        }

        // Load content for each deferred element

        for (let i = 0; i < oembedVideos.length; i++) {
            loadContent(oembedVideos[i]);
        }

        return true;
    }

    /* Init
        window.addEventListener('load', lazyOembeds(), false);
    */
}

function triggerResize(timer = 1000) {
    setTimeout(function() {
        // HACK: Reset position of parallax.js window
        window.dispatchEvent(new Event("resize"));
    }, timer);
}
